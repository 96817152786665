import get from 'lodash/get';
import { useGoal } from 'gatsby-plugin-fathom'

export default function(cta, opts = {}) {
  let result = {};

  if (!cta) return result;

  if (cta.link) {
    result = {
      external: true,
      href: cta.link,
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  }

  if (get(cta, 'route.slug.current')) {
    let href = cta.route.slug.current;

    if (opts.query) {
      href = `${href}${opts.query}`;
    }

    result = { href };
  }

  if (get(cta, 'fathomGoalId')) {
    result.onClick =  useGoal(cta.fathomGoalId, true);
  }

  return result;
}
