import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import colors from '@utils/colors';
import { RoughOne, RoughThree, Swirl } from '@components/vector';
import * as styled from './styles';

function UpNext({ items }) {
  return (
    <styled.Wrapper>
      <Swirl color={colors.B200} />
      <styled.Background>
        <RoughThree />
        <div />
        <RoughOne />
      </styled.Background>
      <styled.Inner>
        {items.map((item, idx) => (
          <styled.Item key={idx}>
            {get(item, 'image.asset.fluid') && (
              <styled.Image fluid={item.image.asset.fluid} edge={idx + 1} ratio={2 / 3} altText={item.imageExtended?.altText}/>
            )}
            <styled.Heading levels={[400, 200]}>
              {item.heading}
            </styled.Heading>
            <styled.Body sizes={['small', 'regular']}>
              {item.description}
            </styled.Body>
            {get(item, 'cta.route.slug.current') ? (
              <styled.Link to={item.cta.route.slug.current}>
                {get(item, 'cta.label')}
              </styled.Link>
            ) : get(item, 'cta.link') && (
              <styled.Link external href={item.cta.link}>
                {get(item, 'cta.label')}
              </styled.Link>
            )}
          </styled.Item>
        ))}
      </styled.Inner>
    </styled.Wrapper>
  );
}

UpNext.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.object,
    link: PropTypes.string,
    linkLabel: PropTypes.string,
  })),
};

export default UpNext;
