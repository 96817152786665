import styled from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import UnstyledImage from '@components/Image';
import { Body as UnstyledBody, CTA, Heading as UnstyledHeading } from '@components/type';

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  & > svg:first-of-type {
    fill: ${colors.B100};
    vertical-align: bottom;
  }

  & > div {
    flex: 1;
    background-color: ${colors.B100};
  }

  & > svg:last-of-type {
    fill: ${colors.B100};
    transform: scale(-1, -1) translateY(1px);
    vertical-align: top;
  }

  ${mq.gtsm`
    top: 90px;
  `}
`;

export const Body = styled(UnstyledBody)`

`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
`;

export const Image = styled(UnstyledImage)`
  margin-bottom: 16px;
`;

export const Inner = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 ${H_PADDING_MOBILE} 48px;
  transform: translateY(-10px);

  ${mq.gtmd`
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    transform: translateY(-72px);
  `}
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  ${mq.gtmd`
    flex: 1;
    margin: 0;

    &:first-child {
      margin-right: 64px;
    }
  `}
`;

export const Link = styled(CTA)`
  display: inline-block;
  margin-top: 16px;
  color: ${colors.B500};

  ${mq.gtsm`
    margin-top: 40px;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  padding-top: 30px;
  overflow: hidden;

  & > svg:first-child {
    position: absolute;
    top: 0;
    right: -10vw;
    left: -10vw;
    width: 120vw;
    z-index: 1;
    transform: scale(-1, 1);
  }

  ${mq.gtsm`
    padding-top: 120px;

    & > svg:first-child {
      right: 0;
      left: 0;
      width: 66vw;
      margin: 0 auto;

      & path {
        stroke-width: 2px;
      }
    }
  `}
`;
